import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

// Angular Material
import { AppMaterialModule } from './app-material.module';

// Pipes
import { FilterPipe } from './pipe/filter.pipe';

// Services
import { AsideService } from './shared/aside.service';
import { ApiService } from './shared/api.service';
import { AuthService } from './shared/auth.service';
import { UiService } from './shared/ui.service';

// Routes
import { routes } from './app.routes';

// App Components
import { AppComponent } from './app.component';
import { AsideComponent } from './aside/aside.component';
import { LightboxComponent } from './lightbox/lightbox.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { DebriefComponent } from './debrief/debrief.component';



@NgModule({
  declarations: [
    AppComponent,
    AsideComponent,
    LightboxComponent,
    FilterPipe,
    DashboardComponent,
    LoginComponent,
    PageNotFoundComponent,
    DebriefComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppMaterialModule,
    HttpModule,
    HttpClientModule,
    ReactiveFormsModule,
    routes
  ],
  providers: [AsideService, ApiService, AuthService, UiService],
  bootstrap: [AppComponent]
})
export class AppModule { }
