import { OnInit, Component, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AsideService } from './shared/aside.service';
import { MatSidenav } from "@angular/material/sidenav";

import { filter } from 'rxjs/operators';

import { ApiService } from './shared/api.service';
import { AuthService } from './shared/auth.service';
import { LightboxService } from './shared/lightbox.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';

  @ViewChild('asidenav') public asidenav: MatSidenav;
  @ViewChild('sidenav') public sidenav: MatSidenav;

  // Change menuside to 'over' and menuopen to 'false'
  // if you wish to by default hide the menu on the left
  menuside                    = 'side';
  menuopen                    = true;

  // Hide menu button and header
  menubtn                     = false;
  showheader                  = false;

  admininitials               = ' ';
  administrator               = ' ';
  adminemail                  = ' ';

  // Public URLs like login
  publicUrl                   = ['/login','/'];
  
  public constructor(
    public lightbox: LightboxService,
    public aside: AsideService,
    private auth: AuthService,
    private router: Router ) { }

  ngOnInit(){

    // If this is a public page remove the header
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd)
      ).subscribe(data => {
          
        if( this.publicUrl.includes( data['url'] ) ) {            
          this.menuopen       = false;
          this.showheader     = false;
          this.menubtn        = false;
        } else {
          if(this.menuside != 'over') {
            this.menuopen     = true;
            this.menubtn      = true;
          } else {
            this.menubtn      = false;
          }
          this.showheader     = true;
          
          /*if ( ! this.auth.isLoggedIn ) {
            this.router.navigate(["login"]);
            return  false;
          } else {
            /*this.administrator  = this.auth.getUserName();
            this.adminemail     = this.auth.getUserEmail();
            let initials        = this.administrator.split(" ");
            let admininitials   = "";
            for(let initial of initials) {
              admininitials    += initial.substring(0, 1).toUpperCase();
            }
            this.admininitials  = admininitials;
          }*/
        }
      });

  }

  ngOnViewInit() {
    // Setup the aside drawer
    this.aside.setSidenav(this.asidenav);
  }

  closeMenu() {
    this.sidenav.toggle();
    if(this.menuside != 'over') {
      this.menubtn            = false;
    }
  }

  openMenu() {
    this.sidenav.toggle();
    if(this.menuside != 'over') {
      this.menubtn            = true;
    }
  }

  logOut(){
    this.auth.logout();
    if(this.menuside == 'over') {
      this.sidenav.toggle();
    }
  }

}
