import { Routes, RouterModule } from "@angular/router";

// App Component.
// -------------
import { LoginComponent } from "./login/login.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";


import { DebriefComponent } from "./debrief/debrief.component";


export const router: Routes = [

    { path: '', redirectTo: 'debrief', pathMatch: 'full' },
    { path: 'debrief/:id', component: DebriefComponent },
    { path: 'login', component: LoginComponent },
    { path: 'overview', component: DashboardComponent},
    // { path: 'overview', component: DashboardComponent},
    { path: '**', component: PageNotFoundComponent }

];

export const routes = RouterModule.forRoot(router, { relativeLinkResolution: 'legacy' });
