import { Component, OnInit, ViewChild } from '@angular/core';

import { UiService } from '../shared/ui.service';
import { ApiService } from '../shared/api.service';
import { AuthService } from '../shared/auth.service';
import { ActivatedRoute } from '@angular/router';

import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-debrief',
  templateUrl: './debrief.component.html',
  styleUrls: ['./debrief.component.scss']
})
export class DebriefComponent implements OnInit {

  @ViewChild('stepper') stepper : MatStepper;
  @ViewChild('video1') video1;
  @ViewChild('video2') video2;
  @ViewChild('video4') video4;
  @ViewChild('video5') video5;

  assessmentId: number;
  groupId: number;

  isIntro                         = true;
  isLinear                        = false;
  isLoading                       = true;
  church: any;

  qCount                          = 0;
  total                           = 0;

  peopleScore                     = 0;
  placeScore                      = 0;
  processesScore                  = 0;
  productScore                    = 0;

  peopleTotal                     = 0;
  placeTotal                      = 0;
  processesTotal                  = 0;
  productTotal                    = 0;

  positiveAnswers                 = [];
  negativeAnswers                 = [];

  step1Timer: any;
  step2Timer: any;
  step3Timer: any;
  step4Timer: any;

  q1Total                         = 0;
  q2Total                         = 0;
  q3Total                         = 0;
  q4Total                         = 0;
  q5Total                         = 0;
  q6Total                         = 0;
  q7Total                         = 0;
  q8Total                         = 0;
  q9Total                         = 0;
  q10Total                        = 0;
  q11Total                        = 0;
  q12Total                        = 0;
  q13Total                        = 0;
  q14Total                        = 0;
  q15Total                        = 0;
  q16Total                        = 0;
  q17Total                        = 0;
  q18Total                        = 0;
  q19Total                        = 0;
  q20Total                        = 0;
  q21Total                        = 0;
  q21TotalFormat                  = 0;

  questions = [
    {id: 'q1', question: 'It’s easy for guests to locate the parking lots, entrances, restrooms, kids’ areas, and where to sit during service.'},
    {id: 'q2', question: 'We intentionally station volunteers where guests will potentially make decisions.'},
    {id: 'q3', question: 'Our cleanliness, paint, fixtures, supplies, and scents remind guests of a five-star hotel.'},
    {id: 'q4', question: 'The noise level in our building feels exciting but not overpowering.'},
    {id: 'q5', question: 'Our parking lots, lobbies, and worship center are at or less than 80% full.'},
    {id: 'q6', question: 'Our volunteers are well-trained and regularly show up on time.'},
    {id: 'q7', question: 'Our volunteers have caught the vision cast by leadership and are always enthusiastic to serve.'},
    {id: 'q8', question: 'Our volunteers have a healthy attitude of serving and are prepared to go beyond just their scheduled time.'},
    {id: 'q9', question: 'Our volunteers save their conversations with friends for after service and are actively on the lookout to welcome new guests.'},
    {id: 'q10', question: 'Our volunteers invite their friends to join their team.'},
    {id: 'q11', question: 'Volunteers are always well-informed and never tell me, “I didn\'t know.”'},
    {id: 'q12', question: 'People interested in volunteering are clear about their next steps and follow through on joining a team.'},
    {id: 'q13', question: 'Our volunteers are empowered to make spontaneous decisions without checking in with their team leader.'},
    {id: 'q14', question: 'Our children’s rooms are safe, clean, and organized, so parents aren\'t forced to bring their kids to the service.'},
    {id: 'q15', question: 'Our volunteers and staff are easy to identify and are able to provide answers when guests have questions.'},
    {id: 'q16', question: 'People walk out of church feeling better than when they came in.'},
    {id: 'q17', question: 'We are intentional about serving high-quality coffee and snacks so that our guests feel valued.'},
    {id: 'q18', question: 'We understand our guests and their preferences – where they like to eat and what experiences they are used to.'},
    {id: 'q19', question: 'We know the right volunteers are in the right places because guests frequently ask them for prayer.'},
    {id: 'q20', question: 'We offer clear next steps to our guests to make it easier for them to connect with our church.'},
  ];

  posQs                           = [];
  negQs                           = [];

  newQTotals                      = [];
  positiveQs                      = [];
  negativeQs                      = [];

  hurdles                         = [];
  urgency                         = 0;

  startStep1Time                  = "5:00";
  startStep2Time                  = "5:00";
  startStep3Time                  = "10:00";
  startStep4Time                  = "10:00";

  step1TimerRun                   = false;
  step2TimerRun                   = false;
  step3TimerRun                   = false;
  step4TimerRun                   = false;

  urgencyCountOne                 = 0;
  urgencyCountTwo                 = 0;
  urgencyCountThree               = 0;
  urgencyCountFour                = 0;
  urgencyCountFive                = 0;
  urgencyTotal                    = 0;

  constructor(
    private ui: UiService,
    private api: ApiService,
    private auth: AuthService,
    private activeroute: ActivatedRoute ) { }

  ngOnInit(): void {

    this.assessmentId             = parseInt(this.activeroute.snapshot.params['id']);

    // First validate JWT token
    const payload = {};
    this.api.post('wp-json/jwt-auth/v1/token/validate', payload)
      .subscribe(data => {
        this.getAssessment();
      }, err => {
        this.getNewToken();
        console.log(err);
      });

  }

  ngAfterViewInit() {
    
  }

  getStarted() {
    this.isIntro                  = false;
  }

  getNewToken() {
    
    const payload = {
      "username": "agitated-khorana",
      "password": "CHcJu3NytKxSzcLrNd"
    };
    this.api.post('wp-json/jwt-auth/v1/token', payload)
      .subscribe(data => {
        console.log(data);
        this.auth.setToken(data.token);
        this.getAssessment();
      }, err => {
        console.log(err);
        this.ui.showSnackbar("Network Error, Please check the network and do it again.", "failure");
      });

  }

  myVideoLoaded() {
    console.log(this.video1);
    console.log(this.video1.nativeElement);
    console.log(this.video1.nativeElement.children);
    console.log(this.video1.nativeElement.children[0]);
  }

  stopVideo(page) {
    if(page == 1) {
      var iframe      = this.video1.nativeElement.children[0];
    }
    if(page == 2) {
      var iframe      = this.video2.nativeElement.children[0];
    }
    if(page == 4) {
      var iframe      = this.video4.nativeElement.children[0];
    }
    if(page == 5) {
      var iframe      = this.video5.nativeElement.children[0];
    }
    
    if(iframe) {
      // var video       = iframe.contentWindow.document.getElementsByTagName( 'video' );
      // console.log(video); 
      var iframeSrc   = iframe.src;
      iframe.src      = iframeSrc;
    }
    // if ( video ) {
    //   video.pause();
    // }
  };

  getAssessment() {
    this.api.get('wp-json/acf/v3/posts/' + this.assessmentId)
      .subscribe(data => {
        console.log(data);
        this.groupId              = data.acf.group_assessment_id;
        this.church               = data.acf.organization_name;
        console.log(this.groupId);
        this.qCount++;

        let total                 = parseFloat(data.acf.q1) + parseFloat(data.acf.q2) + parseFloat(data.acf.q3) + parseFloat(data.acf.q4) + parseFloat(data.acf.q5) + parseFloat(data.acf.q6) + parseFloat(data.acf.q7) + parseFloat(data.acf.q8) + parseFloat(data.acf.q9) + parseFloat(data.acf.q10) + parseFloat(data.acf.q11) + parseFloat(data.acf.q12) + parseFloat(data.acf.q13) + parseFloat(data.acf.q14) + parseFloat(data.acf.q15) + parseFloat(data.acf.q16) + parseFloat(data.acf.q17) + parseFloat(data.acf.q18) + parseFloat(data.acf.q19) + parseFloat(data.acf.q20);
        
        this.updateWellAndAttention(data.acf);

        this.q1Total              = this.q1Total + parseFloat(data.acf.q1);
        this.q2Total              = this.q2Total + parseFloat(data.acf.q2);
        this.q3Total              = this.q3Total + parseFloat(data.acf.q3);
        this.q4Total              = this.q4Total + parseFloat(data.acf.q4);
        this.q5Total              = this.q5Total + parseFloat(data.acf.q5);
        this.q6Total              = this.q6Total + parseFloat(data.acf.q6);
        this.q7Total              = this.q7Total + parseFloat(data.acf.q7);
        this.q8Total              = this.q8Total + parseFloat(data.acf.q8);
        this.q9Total              = this.q9Total + parseFloat(data.acf.q9);
        this.q10Total             = this.q10Total + parseFloat(data.acf.q10);
        this.q11Total             = this.q11Total + parseFloat(data.acf.q11);
        this.q12Total             = this.q12Total + parseFloat(data.acf.q12);
        this.q13Total             = this.q13Total + parseFloat(data.acf.q13);
        this.q14Total             = this.q14Total + parseFloat(data.acf.q14);
        this.q15Total             = this.q15Total + parseFloat(data.acf.q15);
        this.q16Total             = this.q16Total + parseFloat(data.acf.q16);
        this.q17Total             = this.q17Total + parseFloat(data.acf.q17);
        this.q18Total             = this.q18Total + parseFloat(data.acf.q18);
        this.q19Total             = this.q19Total + parseFloat(data.acf.q19);
        this.q20Total             = this.q20Total + parseFloat(data.acf.q20);
        this.q21Total             = this.q21Total + parseFloat(data.acf.q21);
        this.q21TotalFormat       = this.q21TotalFormat + (parseFloat(data.acf.q21) * 4) + 1;
        this.hurdles.push(data.acf.q23);

        if(data.acf.q21 == '0') { this.urgencyCountOne++; }
        if(data.acf.q21 == '0.25') { this.urgencyCountTwo++; }
        if(data.acf.q21 == '0.50') { this.urgencyCountThree++; }
        if(data.acf.q21 == '0.75') { this.urgencyCountFour++; }
        if(data.acf.q21 == '1') { this.urgencyCountFive++; }

        console.log(this.q1Total);

        this.peopleScore          = this.peopleScore + parseFloat(data.acf.people_score);
        this.placeScore           = this.placeScore + parseFloat(data.acf.place_score);
        this.processesScore       = this.processesScore + parseFloat(data.acf.processes_score);
        this.productScore         = this.productScore + parseFloat(data.acf.product_score);

        this.api.get('wp-json/acf/v3/posts/' + this.groupId)
          .subscribe(data => {
            if(data.acf.drift_assessment_result_data && data.acf.drift_assessment_result_data.length > 0) {
              
              for(let qs of data.acf.drift_assessment_result_data) {

                total               = total + parseFloat(qs.q1) + parseFloat(qs.q2) + parseFloat(qs.q3) + parseFloat(qs.q4) + parseFloat(qs.q5) + parseFloat(qs.q6) + parseFloat(qs.q7) + parseFloat(qs.q8) + parseFloat(qs.q9) + parseFloat(qs.q10) + parseFloat(qs.q11) + parseFloat(qs.q12) + parseFloat(qs.q13) + parseFloat(qs.q14) + parseFloat(qs.q15) + parseFloat(qs.q16) + parseFloat(qs.q17) + parseFloat(qs.q18) + parseFloat(qs.q19) + parseFloat(qs.q20);

                console.log(qs);
                this.qCount++;

                this.updateWellAndAttention(qs);

                this.q1Total        = this.q1Total + parseFloat(qs.q1);
                this.q2Total        = this.q2Total + parseFloat(qs.q2);
                this.q3Total        = this.q3Total + parseFloat(qs.q3);
                this.q4Total        = this.q4Total + parseFloat(qs.q4);
                this.q5Total        = this.q5Total + parseFloat(qs.q5);
                this.q6Total        = this.q6Total + parseFloat(qs.q6);
                this.q7Total        = this.q7Total + parseFloat(qs.q7);
                this.q8Total        = this.q8Total + parseFloat(qs.q8);
                this.q9Total        = this.q9Total + parseFloat(qs.q9);
                this.q10Total       = this.q10Total + parseFloat(qs.q10);
                this.q11Total       = this.q11Total + parseFloat(qs.q11);
                this.q12Total       = this.q12Total + parseFloat(qs.q12);
                this.q13Total       = this.q13Total + parseFloat(qs.q13);
                this.q14Total       = this.q14Total + parseFloat(qs.q14);
                this.q15Total       = this.q15Total + parseFloat(qs.q15);
                this.q16Total       = this.q16Total + parseFloat(qs.q16);
                this.q17Total       = this.q17Total + parseFloat(qs.q17);
                this.q18Total       = this.q18Total + parseFloat(qs.q18);
                this.q19Total       = this.q19Total + parseFloat(qs.q19);
                this.q20Total       = this.q20Total + parseFloat(qs.q20);
                this.q21Total       = this.q21Total + parseFloat(qs.q21);
                this.q21TotalFormat = this.q21TotalFormat + (parseFloat(qs.q21) * 4) + 1;
                this.hurdles.push(qs.q23);

                if(qs.q21 == '0') { this.urgencyCountOne++; }
                if(qs.q21 == '0.25') { this.urgencyCountTwo++; }
                if(qs.q21 == '0.50') { this.urgencyCountThree++; }
                if(qs.q21 == '0.75') { this.urgencyCountFour++; }
                if(qs.q21 == '1') { this.urgencyCountFive++; }

                this.peopleScore    = this.peopleScore + parseFloat(qs.people_score);
                this.placeScore     = this.placeScore + parseFloat(qs.place_score);
                this.processesScore = this.processesScore + parseFloat(qs.processes_score);
                this.productScore   = this.productScore + parseFloat(qs.product_score);

              }

            }

            console.log(total);
            let count               = this.qCount * 20;
            this.total              = Math.round((total / count) * 100);
            let peopleTotal         = Math.round(((this.peopleScore / this.qCount) * 100) / 5);
            let placeTotal          = Math.round(((this.placeScore / this.qCount) * 100) / 5);
            let processesTotal      = Math.round(((this.processesScore / this.qCount) * 100) / 5);
            let productTotal        = Math.round(((this.productScore / this.qCount) * 100) / 5);
            this.peopleTotal        = peopleTotal;
            this.placeTotal         = placeTotal;
            this.processesTotal     = processesTotal;
            this.productTotal       = productTotal;
            console.log(this.placeScore);
            console.log(this.processesScore);
            console.log(this.productScore);
            console.log(this.total);

            this.isLoading          = false ;
            console.log(this.video1);

            this.finalizeCounts();
            this.getAverages(this.qCount);

          });

      });
  }

  moveStepper(index: number, stepper: MatStepper) {    
    stepper.selectedIndex     = index - 1;
  }

  setMarginLeft(value) {
    let per                   = value - 5;
    return per + '%';
  }

  setMarginTotalLeft(value) {
    let per                   = value - 1;
    return per + '%';
  }

  showCorrectScore() {
    if(this.total < 40) {
      return "It looks like your church needs to work on prioritizing <br/> your guests and creating a better experience for them.";
    } else if(this.total < 70) {
      return "While your church is doing well at receiving guests, <br/> it can always do more for a better guest experience.";
    } else {
      return "Congratulations! It looks like your church is already doing a <br/> great job at receiving guests and making them feel at home.";
    }
  }

  updateWellAndAttention(data) {
    
    console.log(data);
    for(let q = 1;q <= 20;q++ ) {
      if(data['q' + q] == "1") {
        this.positiveAnswers.push(q);
        console.log("This question plus one " + q);
      } else if(data['q' + q] == "0") {
        this.negativeAnswers.push(q);
        console.log("This question plus one " + q);
      }
    }
    console.log(this.positiveAnswers);
    console.log(this.negativeAnswers);

  }

  finalizeCounts() {

    /*const poscounts   = {};
    const negcounts   = {};
    const positiveqs  = [];
    const negativeqs  = [];

    for (const num of this.positiveAnswers) {
      poscounts[num] = poscounts[num] ? poscounts[num] + 1 : 1;
    }

    for(let p = 1;p <= 20;p++) {
      if(poscounts[p]) {
        let question = 'q' + p;
        positiveqs.push({
          counts: poscounts[p],
          question: question
        });
      }
    }
    positiveqs.sort((a, b) => (a.counts < b.counts) ? 1 : -1);
    console.log(positiveqs);

    for(let ps = 0;ps < 3;ps++) {
      let question        = this.questions.find(function (obj) { return positiveqs[ps].question == obj.id;});
      this.posQs.push(question);
    }

    for (const num of this.negativeAnswers) {
      negcounts[num] = negcounts[num] ? negcounts[num] + 1 : 1;
    }
    for(let n = 1;n <= 20;n++) {
      if(negcounts[n]) {
        let question = 'q' + n;
        negativeqs.push({
          counts: negcounts[n],
          question: question
        });
      }
    }
    negativeqs.sort((a, b) => (a.counts < b.counts) ? 1 : -1);
    console.log(negativeqs);

    for(let ns = 0;ns < 3;ns++) {
      let question        = this.questions.find(function (obj) { return negativeqs[ns].question == obj.id;});
      this.negQs.push(question);
    }
    console.log(this.posQs);
    console.log(this.negQs);*/

  }

  startStep1Timer() {
    let presentTime     = this.startStep1Time;
    let timeArray       = presentTime.split(/[:]+/);
    let m               = parseInt(timeArray[0]);
    let s               = this.checkSecond((parseInt(timeArray[1]) - 1));
    if(s == 59){ m = m-1; }
    if(m < 0){
      return
    }
  
    this.startStep1Time = m + ":" + s;
    this.step1TimerRun  = true;
    this.step1Timer     = window.setTimeout(() => {this.startStep1Timer();}, 1000);
  
  }

  pauseStep1Timer() {
    this.step1TimerRun  = false;
    window.clearTimeout(this.step1Timer);
  }

  startStep2Timer() {
    let presentTime     = this.startStep2Time;
    let timeArray       = presentTime.split(/[:]+/);
    let m               = parseInt(timeArray[0]);
    let s               = this.checkSecond((parseInt(timeArray[1]) - 1));
    if(s == 59){ m = m-1; }
    if(m < 0){
      return
    }
  
    this.startStep2Time = m + ":" + s;
    this.step2TimerRun  = true;
    this.step2Timer     = setTimeout(() => {this.startStep2Timer();}, 1000);
  
  }

  pauseStep2Timer() {
    this.step2TimerRun  = false;
    window.clearTimeout(this.step2Timer);
  }

  startStep3Timer() {
    let presentTime     = this.startStep3Time;
    let timeArray       = presentTime.split(/[:]+/);
    let m               = parseInt(timeArray[0]);
    let s               = this.checkSecond((parseInt(timeArray[1]) - 1));
    if(s == 59){ m = m-1; }
    if(m < 0){
      return
    }
  
    this.startStep3Time = m + ":" + s;
    this.step3TimerRun  = true;
    this.step3Timer     = setTimeout(() => {this.startStep3Timer();}, 1000);
  
  }

  pauseStep3Timer() {
    this.step3TimerRun  = false;
    window.clearTimeout(this.step3Timer);
  }

  startStep4Timer() {
    let presentTime     = this.startStep4Time;
    let timeArray       = presentTime.split(/[:]+/);
    let m               = parseInt(timeArray[0]);
    let s               = this.checkSecond((parseInt(timeArray[1]) - 1));
    if(s == 59){ m = m-1; }
    if(m < 0){
      return
    }
  
    this.startStep4Time = m + ":" + s;
    this.step4TimerRun  = true;
    this.step4Timer     = setTimeout(() => {this.startStep4Timer();}, 1000);
  
  }

  pauseStep4Timer() {
    this.step4TimerRun  = false;
    window.clearTimeout(this.step4Timer);
  }

  checkSecond(sec) {
    if (sec < 10 && sec >= 0) {sec = "0" + sec}; // add zero in front of numbers < 10
    if (sec < 0) {sec = "59"};
    return sec;
  }

  startTheTimer(timer) {
    if(timer == 1) {
      this.startStep1Timer();
    } else if(timer == 2) {
      this.startStep2Timer();
    } else if(timer == 3) {
      this.startStep3Timer();
    } else if(timer == 4) {
      this.startStep4Timer();
    }
  }

  pauseTheTimer(timer) {
    if(timer == 1) {
      this.pauseStep1Timer();
    } else if(timer == 2) {
      this.pauseStep2Timer();
    } else if(timer == 3) {
      this.pauseStep3Timer();
    } else if(timer == 4) {
      this.pauseStep4Timer();
    }
  }

  getAverages(qCounts) {

    let q1Total         = Math.round((this.q1Total / qCounts) * 100);
    this.newQTotals.push({question: 'It’s easy for guests to locate the parking lots, entrances, restrooms, kids’ areas, and where to sit during service.', value: q1Total});
    let q2Total         = Math.round((this.q2Total / qCounts) * 100);
    this.newQTotals.push({question: 'We intentionally station volunteers where guests will potentially make decisions.', value: q2Total});
    let q3Total         = Math.round((this.q3Total / qCounts) * 100);
    this.newQTotals.push({question: 'Our cleanliness, paint, fixtures, supplies, and scents remind guests of a five-star hotel.', value: q3Total});
    let q4Total         = Math.round((this.q4Total / qCounts) * 100);
    this.newQTotals.push({question: 'The noise level in our building feels exciting but not overpowering.', value: q4Total});
    let q5Total         = Math.round((this.q5Total / qCounts) * 100);
    this.newQTotals.push({question: 'Our parking lots, lobbies, and worship center are at or less than 80% full.', value: q5Total});
    let q6Total         = Math.round((this.q6Total / qCounts) * 100);
    this.newQTotals.push({question: 'Our volunteers are well-trained and regularly show up on time.', value: q6Total});
    let q7Total         = Math.round((this.q7Total / qCounts) * 100);
    this.newQTotals.push({question: 'Our volunteers have caught the vision cast by leadership and are always enthusiastic to serve.', value: q7Total});
    let q8Total         = Math.round((this.q8Total / qCounts) * 100);
    this.newQTotals.push({question: 'Our volunteers have a healthy attitude of serving and are prepared to go beyond just their scheduled time.', value: q8Total});
    let q9Total         = Math.round((this.q9Total / qCounts) * 100);
    this.newQTotals.push({question: 'Our volunteers save their conversations with friends for after service and are actively on the lookout to welcome new guests.', value: q9Total});
    let q10Total         = Math.round((this.q10Total / qCounts) * 100);
    this.newQTotals.push({question: 'Our volunteers invite their friends to join their team.', value: q10Total});
    let q11Total         = Math.round((this.q11Total / qCounts) * 100);
    this.newQTotals.push({question: 'Volunteers are always well-informed and never tell me, “I didn\'t know.”', value: q11Total});
    let q12Total         = Math.round((this.q12Total / qCounts) * 100);
    this.newQTotals.push({question: 'People interested in volunteering are clear about their next steps and follow through on joining a team.', value: q12Total});
    let q13Total         = Math.round((this.q13Total / qCounts) * 100);
    this.newQTotals.push({question: 'Our volunteers are empowered to make spontaneous decisions without checking in with their team leader.', value: q13Total});
    let q14Total         = Math.round((this.q14Total / qCounts) * 100);
    this.newQTotals.push({question: 'Our children’s rooms are safe, clean, and organized, so parents aren\'t forced to bring their kids to the service.', value: q14Total});
    let q15Total         = Math.round((this.q15Total / qCounts) * 100);
    this.newQTotals.push({question: 'Our volunteers and staff are easy to identify and are able to provide answers when guests have questions.', value: q15Total});
    let q16Total         = Math.round((this.q16Total / qCounts) * 100);
    this.newQTotals.push({question: 'People walk out of church feeling better than when they came in.', value: q16Total});
    let q17Total         = Math.round((this.q17Total / qCounts) * 100);
    this.newQTotals.push({question: 'We are intentional about serving high-quality coffee and snacks so that our guests feel valued.', value: q17Total});
    let q18Total         = Math.round((this.q18Total / qCounts) * 100);
    this.newQTotals.push({question: 'We understand our guests and their preferences – where they like to eat and what experiences they are used to.', value: q18Total});
    let q19Total         = Math.round((this.q19Total / qCounts) * 100);
    this.newQTotals.push({question: 'We know the right volunteers are in the right places because guests frequently ask them for prayer.', value: q19Total});
    let q20Total         = Math.round((this.q20Total / qCounts) * 100);
    this.newQTotals.push({question: 'We offer clear next steps to our guests to make it easier for them to connect with our church.', value: q20Total});
    
    let negativeQs      = this.newQTotals.slice(0).sort(function(a, b) {          
      if (a.value === b.value) {
        return a.question > b.question ? 1 : -1;
      }
      return a.value > b.value ? 1 : -1;
    });
    let positiveQs      = this.newQTotals.slice(0).sort(function(a, b) {          
      if (a.value === b.value) {
        return a.question < b.question ? 1 : -1;
      }
      return a.value < b.value ? 1 : -1;
    });
    

    // let positiveQs      = this.newQTotals.slice(0).sort((a, b) => b.value - a.value);
    // let negativeQs      = this.newQTotals.slice(0).sort((a, b) => a.value - b.value);
    this.positiveQs     = positiveQs.slice(0, 3);
    this.negativeQs     = negativeQs.slice(0, 3);
    console.log(this.positiveQs); 
    console.log(this.negativeQs);

    this.urgencyTotal   = this.q21TotalFormat / qCounts;
    this.urgency        = Math.round((Math.round((this.q21Total / qCounts) * 100) / 100) * 80);
    console.log(this.urgency);
    // this.urgencyTotal   = this.urgencyCountOne + this.urgencyCountTwo + this.urgencyCountThree + this.urgencyCountFour + this.urgencyCountFive;
    
    

  }

  getUrgency() {
    return this.urgency + "%";
  }

}
