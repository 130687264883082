<mat-sidenav-container fullscreen>
  <!-- <mat-sidenav class='mainmenu' #sidenav [mode]="menuside" [opened]="menuopen" position="start">
    <nav>

      <a routerLink='/dashboard' class='logo'><img src='./assets/img/logo.png'></a>
      <div class='profile'>
        <div class='profilepic'>{{ admininitials }}</div>
        <div class='profiledetails'>
          {{ administrator }}<br>
          <span>{{ adminemail }}</span>
        </div>
      </div>

      <div class='sub'>

        <h3>Manage</h3>
        <a routerLink="/overview" class="submenu">
          <i class="material-icons">assessment</i> Overview
        </a>
        <a routerLink="/churches" class="submenu">
          <i class="material-icons">group</i> Users
        </a>


        <a routerLink="/categories" (click)="sidenav.toggle()" class="submenu">
          <i class="material-icons">dashboard</i> Categories
        </a>

        <div style='height: 20px;'></div>

      </div>


      <a routerLink="/administrators"><i class="material-icons">security</i> Administrators</a>
      <a href='javascript:void(0);' (click)="logOut()"><i class="fa fa-sign-out" aria-hidden="true"></i> Logout</a>
    </nav>
  </mat-sidenav>-->

  <app-lightbox *ngIf="lightbox.showLightbox"
    [lightboxTemplate]="lightbox.lightboxTemplate"
    [lightboxInfo]="lightbox.lightboxInfo"
    [lightboxWidth]="lightbox.lightboxWidth"
    (onClose)="lightbox.close()"
    (onSelectInfo)="lightbox.lightboxSelectedInfo.next($event.target)">
  </app-lightbox>

  <!-- <mat-toolbar class='header' *ngIf='showheader'>

    <mat-toolbar-row>
      <a href='javascript:void(0);' (click)="closeMenu()" class="closebtn" *ngIf="menubtn"><mat-icon>arrow_left</mat-icon></a>
      <a href='javascript:void(0);' (click)="openMenu()" class="menubtn" *ngIf="!menubtn"><i class="fa fa-bars" aria-hidden="true"></i></a>
    </mat-toolbar-row>

  </mat-toolbar> -->

  <div class='container'>
    <router-outlet></router-outlet>
  </div>

  <mat-sidenav class='aside' #asidenav mode="over" opened="false" position="end" style="height: 100vh; overflow: hidden;">
    <app-aside></app-aside>
  </mat-sidenav>

</mat-sidenav-container>
