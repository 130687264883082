<div class="container">
  
  <header>
    <div class="logo"><img src="./assets/img/church-fuel-logo.png"></div>
    <div class="section" *ngIf="!isIntro">Decision Day</div>
    <div class="church" style="display: flex; align-items: center;"><img src="./assets/img/church-icon.svg" style="margin-top: -3px; margin-right: 10px;"> {{ church }}</div>
  </header>

  <main *ngIf="isLoading">
    <div class="matspinner">
      <mat-spinner strokeWidth="5"></mat-spinner>
    </div>
  </main>
  
  <main *ngIf="isIntro && !isLoading">
    
    <h1>
      Decision Day
      <div class="debrief-time"><img src="./assets/img/time-icon.svg"> 1 hr</div>
    </h1>

    <div class="steps-info">

      <div class="steps-title step1">
        <h2>01</h2>
        Start With Why
      </div>

      <div class="steps-title step2">
        <h2>02</h2>
        Church Spotlight
      </div>

      <div class="steps-title step3">
        <h2>03</h2>
        Review Scores
      </div>

      <div class="steps-title step4">
        <h2>04</h2>
        Why Now?
      </div>

      <div class="steps-title step5">
        <h2>05</h2>
        Next Steps
      </div>

    </div>

    <div class="cta-section">
      <a class="cta" (click)="getStarted()">Let's Get Started</a>
      <span class="note"><strong>Note:</strong> Please ensure your whole team is present for this meeting.</span>
    </div>

  </main>

  <main style="padding-bottom: 10px;margin-top: 0;" *ngIf="!isIntro && !isLoading">

    <div style="height: 17px;"></div>
    <mat-horizontal-stepper #stepper disableRipple=true>
      <mat-step>
        
        <ng-template matStepLabel>
          <div class="step-circle">
            <img src="./assets/img/01-flag-icon.png">
          </div>
        </ng-template>

        <h2>Step 1</h2>
        <h3>Start With Why</h3>
        Watch this video and find out.
        <br><br>

        <div class='embed-container' #video1>
          <iframe (load)="myVideoLoaded()" src="https://player.vimeo.com/video/592965582?h=bb422ccc24&title=0&byline=0&portrait=0" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
        </div>

        <div class="discussion">
          <div class="txt">
            <strong>Discuss:</strong><br>
            Why should your church be guest ready?
          </div>
          <div class="time">
            <div class="timer">
              <img src="./assets/img/pause-button.svg" *ngIf="step1TimerRun" (click)="pauseTheTimer(1)" class="pausebtn">
              <img src="./assets/img/play-button.svg" *ngIf="!step1TimerRun" (click)="startTheTimer(1)" class="playbtn">
              <span [innerHTML]="startStep1Time"></span>
            </div>
            
          </div>
        </div>

        <div class="cta-section next-btn">
          <a class="cta" (click)="moveStepper(2, stepper);stopVideo(1);">Next Step</a>
        </div>

      </mat-step>

      <mat-step>
        
        <ng-template matStepLabel>
          <div class="step-circle">
            <img src="./assets/img/02-tick-icon.png">
          </div>
        </ng-template>

        <h2>Step 2</h2>
        <h3>Church Spotlight</h3>
        Here’s an example of a church that is intentional about being guest ready.<br><br>

        <div class='embed-container' #video2>
          <iframe src="https://player.vimeo.com/video/592966077?h=73a5af5aa8&title=0&byline=0&portrait=0" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
        </div>

        <div class="discussion">
          <div class="txt">
            <strong>Discuss:</strong><br>
            What from this example inspired you?
          </div>
          <div class="time">
            <div class="timer">
              <img src="./assets/img/pause-button.svg" *ngIf="step2TimerRun" (click)="pauseTheTimer(2)" class="pausebtn">
              <img src="./assets/img/play-button.svg" *ngIf="!step2TimerRun" (click)="startTheTimer(2)" class="playbtn">
              <span [innerHTML]="startStep2Time"></span>
            </div>
          </div>
        </div>

        <div class="cta-section next-btn">
          <a class="cta" (click)="moveStepper(3, stepper);stopVideo(2);">Next Step</a>
        </div>
        
      </mat-step>

      <mat-step>
        
        <ng-template matStepLabel>
          <div class="step-circle">
            <img src="./assets/img/03-speed-icon.png">
          </div>
        </ng-template>

        <h2>Step 3</h2>
        <h3>Review Scores</h3>
        Compare your team's aggregate results and individual results.

        <div style="text-align: center; width: 100%; margin: 60px auto;">

          <div class="si_ass_result">
            <div class="pervalue">
              <h4 class="nimp" style="min-width: 45%;"></h4> 
              <h4 class="mexpe" style="min-width: 25.5%;"></h4>
              <h4 class="eexpe" style="min-width: 29%;"></h4>
            </div>
            <div data-asspro="{{ total }}" class="si_ass_progress">
              <div class="progress_line" [style.left]="setMarginTotalLeft(total)"></div>
              <span [style.left]="setMarginLeft(total)">{{ total }}%</span>
            </div>
            <div class="si_pro_header">
              <h4 class="nimp" style="min-width: 42%;margin-left: 4%;">Needs <span class="slider_br"><br></span>improvement</h4>
              <h4 class="mexpe" style="min-width: 25.5%;">Meets expectations</h4>
              <h4 class="eexpe" style="min-width: 24%;">Exceeds expectations</h4>
            </div> 
          </div>

          <div class="score-txt" [innerHTML]="showCorrectScore()"></div><br><br>

          <h3>Here's how you scored on your 4 Ps:</h3>

          <div class="score-row">
            <div class="score1">PLACE</div>
            <div class="score2">
              <div class="score-value" [style.marginLeft]="setMarginLeft(placeTotal)">{{ this.placeTotal }}%</div>
              <mat-progress-bar [value]="processesTotal"></mat-progress-bar>
            </div>
            <div class="score3">PEOPLE</div>
            <div class="score4">
              <div class="score-value" [style.marginLeft]="setMarginLeft(peopleTotal)">{{ this.peopleTotal }}%</div>
              <mat-progress-bar [value]="peopleTotal"></mat-progress-bar>
            </div>
          </div>

          <div class="score-row">
            
            <div class="score1">PROCESSES</div>
            <div class="score2">
              <div class="score-value" [style.marginLeft]="setMarginLeft(processesTotal)">{{ this.processesTotal }}%</div>
              <mat-progress-bar [value]="processesTotal"></mat-progress-bar>
            </div>
            <div class="score3">PROGRAM</div>
            <div class="score4">
              <div class="score-value" [style.marginLeft]="setMarginLeft(productTotal)">{{ this.productTotal }}%</div>
              <mat-progress-bar [value]="productTotal"></mat-progress-bar>
            </div>
          </div>
        
        </div>

        <div class="positive">
          <h4>Celebrate what you’re doing well</h4>
          <ol>
            <li *ngFor="let question of positiveQs">{{ question.question }}</li>
          </ol>
        </div>

        <div class="negative">
          <h4>Focus on what needs immediate attention</h4>
          <ol>
            <li *ngFor="let question of negativeQs">{{ question.question }}</li>
          </ol>
        </div>

        <div style="height: 15px;"></div>

        <div class="discussion">
          <div class="txt">
            <strong>Discuss:</strong><br>
            What surprised you and what do you need to specifically work on?
          </div>
          <div class="time">
            <div class="timer">
              <img src="./assets/img/pause-button.svg" *ngIf="step3TimerRun" (click)="pauseTheTimer(3)" class="pausebtn">
              <img src="./assets/img/play-button.svg" *ngIf="!step3TimerRun" (click)="startTheTimer(3)" class="playbtn">
              <span [innerHTML]="startStep3Time"></span>
            </div>
          </div>
        </div>

        <div class="cta-section next-btn">
          <a class="cta" (click)="moveStepper(4, stepper)">Next Step</a>
        </div>
        
      </mat-step>

      <mat-step>
        
        <ng-template matStepLabel>
          <div class="step-circle">
            <img src="./assets/img/04-shield-icon.png">
          </div>
        </ng-template>

        <h2>Step 4</h2>
        <h3>Why Now</h3>
        Find out why it's important to take immediate action.
        <br><br>
        
        <div class='embed-container' #video4>
          <iframe src="https://player.vimeo.com/video/592966335?h=94151cf56e&title=0&byline=0&portrait=0" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
        </div>

        <div style="height: 20px;"></div>

        <div class="urg_score">
          <p>Team Average Urgency Score</p>
          <div class="urgency_score">
            <div class="participant-count-text">Participant Votes</div>

            <div class="horizontal-bars one"></div>
            <div class="horizontal-bars two"></div>
            <div class="horizontal-bars three"></div>
            <div class="horizontal-bars four"></div>
            <div class="horizontal-bars five"></div>

            <div class="participant-counts one">{{ urgencyCountOne }}</div>
            <div class="participant-counts two">{{ urgencyCountTwo }}</div>
            <div class="participant-counts three">{{ urgencyCountThree }}</div>
            <div class="participant-counts four">{{ urgencyCountFour }}</div>
            <div class="participant-counts five">{{ urgencyCountFive }}</div>

            <span class="fill-score" [style.width]="getUrgency()"></span>
            <div class="score" [style.marginLeft]="getUrgency()">{{ urgencyTotal }}</div>	
            <ul id="labels-range-1" class="group-urgency-labels" data-field="180" style="padding-top: 105px;">
              <li class="">Not urgent</li>
              <li>Hardly urgent</li>
              <li>Unsure</li>
              <li>Slightly urgent</li>
              <li>Absolutely urgent</li>
            </ul>

          </div> 
        </div>

        <h4 class="hurdle-header">Hurdles</h4>
        <div class="hurdles">
          <ul>
            <li *ngFor="let hurdle of hurdles">{{ hurdle }}</li>
          </ul>
        </div>
        <div style="height: 20px;"></div>

        <div class="discussion">
          <div class="txt">
            <strong>Discuss:</strong><br>
            What would be our top hurdle to getting started?
          </div>
          <div class="time">
            <div class="timer">
              <img src="./assets/img/pause-button.svg" *ngIf="step4TimerRun" (click)="pauseTheTimer(4)" class="pausebtn">
              <img src="./assets/img/play-button.svg" *ngIf="!step4TimerRun" (click)="startTheTimer(4)" class="playbtn">
              <span [innerHTML]="startStep4Time"></span>
            </div>
          </div>
        </div>

        <div class="cta-section next-btn">
          <a class="cta" (click)="moveStepper(5, stepper);stopVideo(4)">Next Step</a>
        </div>
        
      </mat-step>

      <mat-step>
        
        <ng-template matStepLabel>
          <div class="step-circle">
            <img src="./assets/img/05-rocket-icon.png">
          </div>
        </ng-template>        

        <h2>Step 5</h2>
        <h3>Next Steps</h3>
        Here's how you can start taking actionable steps.
        <br><br>
        
        <div class='embed-container' #video5>
          <iframe src="https://player.vimeo.com/video/592965894?h=94151cf56e&title=0&byline=0&portrait=0" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
        </div><br>

        <div class="downloads">

          <div class="download-block">
            <h4>Digital Guest Follow-Up Template</h4>
            <img src="./assets/img/download-left.png"><br>
            Use this template to plan out the follow-up you'll do with your first-time guests.
            You'll find text message templates and six different email templates you can use
            to engage the guests in your church.<br>
            <a class="download-btn" target="_blank" href="https://cf1.bleat.church/wp-content/uploads/2021/11/Digital_Guest_Follow-Up_Template.pdf">Download</a>
          </div>

          <div class="download-block second-block">
            <h4>First-Time Guest Gift Ideas</h4>
            <img src="./assets/img/download-right1.png"><br>
            Use this resource to plan gifts for your first-time guests. You'll find 10 great
            ideas in this document that you can use in your church to create a welcoming
            environment for first-time guests.<br>
            <a class="download-btn" target="_blank" href="https://cf1.bleat.church/wp-content/uploads/2021/12/First-Time-Guest-Gift-Ideas.pdf">Download</a>
          </div>

        </div>

        <br>

        <div class="bundle">
          <img src="./assets/img/church-fuel-logo.png"><br>
          <div style="height: 10px;"></div>
          <h3>The Guest Ready Bundle</h3>
          The Guest Ready bundle will help you develop a sustainable system
          to be a welcoming church for people in your community.
          <div class="price">
            <div class="line"></div>
            <div class="old">$299</div>
            <div class="new">$199</div> 
          </div>

          <div class="cta-section last-btn">
            <a class="cta" href="https://churchfuel.com/guest-ready/" target="_blank">Get Guest Ready</a>
          </div>

        </div>

        
      </mat-step>
    </mat-horizontal-stepper>

  </main>

</div>
